import React from 'react'
import { Image } from '@/atoms/Image'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions } from '../utils'
import { BaseLogoProps } from './LogoTypes'

export const AppleStoreDownloadLogo: React.FC<BaseLogoProps> = ({ height = 24, className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 83, 24)
  const { t } = useTranslate('app-promo')

  return (
    <Image
      width={scaledWidth}
      height={scaledHeight}
      src={getCloudinaryImageUrl({
        path: '/v1670459352/angel-studios/landing/apple-app-store-badge.webp',
      })}
      className={className}
      alt="Apple App Store Logo"
      aria-label={t('downloadOnTheAppStore', 'Download on the App Store')}
    />
  )
}
